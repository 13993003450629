import React, {Component} from 'react';
import '../CSS/Header.css';
import BurgerMenu from "./BurgerMenu";
import DesktopMenu from "./DesktopMenu";
import Logo from "../Bilder/Logo.png"
import Sabine from "../Bilder/Sabine.jpg";


class Header extends Component {
    render() {
        return (
            <div>

                <div className={"div-burger-header"}>
                    <BurgerMenu/>
                    <div className={"div-titel-header"}>
                        {this.props.titel != null ? <label className={"label-titel-header"}>{this.props.titel}</label> :
                            <label className={"label-titel-header"}>Start</label>
                        }
                    </div>
                </div>

                <div className={"div-praxis-outer-header"}>
                    <div>
                        <img className={"img-logo-header"} src={Logo} alt={"Logo"}/>
                    </div>

                    <div className={"div-praxis-header"}>
                        <label className={"label-praxis-header"}>
                            Praxis <br className={"br"}/> Sabine Jarius
                        </label>
                        <label className={"label-adresse-header"}>
                            Elmoresstr. 2., <span style={{whiteSpace: "nowrap"}}> 51570 Windeck-Schladern </span> <span style={{whiteSpace: "nowrap"}}> Tel.: 02292 - 95 98 786 </span>
                        </label>
                    </div>

                </div>
                {/*--------------------------------------------*/}
                <hr className={"hr-horizontale-linie-header"}/>

                <DesktopMenu page={this.props.page}/>


            </div>

        )
    }

}

export default (Header);