import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import '../CSS/Footer.css';


class Footer extends Component{
    render() {
        return(
            <div className={"div-footer"}>
                <label className={"label-kontakt-footer"}>
                    Kontakt
                </label>
                <label className={"label-anschrift-footer"}>
                    Elmoresstr. 2.
                </label>
                <label className={"label-anschrift-footer"}>
                    51570 Windeck
                </label>
                <label className={"label-anschrift-footer"}>
                    Tel.: 02292 - 95 98 786
                </label>
                <label className={"label-anschrift-footer"}>
                    Fax: 02292 - 95 90 971
                </label>
                <label className={"label-anschrift-footer"}>

                    prasaja[at]t-online.de

                </label>
            </div>

        );
    }

}

export default withRouter(Footer);